<template>
  <div
    class="deck-label"
    :class="classes"
  >
    <!--
      <label> isn't valid html when not referecing an <input /> so we render a
      <b>, which is generally used to "bring attention"
    -->
    <component
      :is="inputRef ? 'label' : 'b'"
      class="deck-label__label"
      :for="inputRef"
    >
      <!-- @slot Default slot to render the label text -->
      <slot>
        {{ text }}
      </slot>
    </component>
    <deck-hinter
      v-if="hint || $slots['hint']"
      class="ml-1"
    >
      <!-- @slot hint Slot to render custom hinter content -->
      <slot name="hint">
        <p class="mb-0">
          {{ hint }}
        </p>
      </slot>
    </deck-hinter>
  </div>
</template>

<script>

export default {
  name: 'DeckLabel',

  components: {
    DeckHinter: defineAsyncComponent(() => import('~/deck/hinter')),
  },

  props: {
    /**
     * The label to display.
     * @type {string}
     * @default undefined
     */
    text: {
      type: String,
      default: undefined,
    },

    /**
     * The typescale size of the label.
     * @type {'dense' | 'small' | 'medium' | string}
     * @default 'dense'
     */
    size: {
      type: String,
      default: 'dense',
    },

    /**
     * The reference to the input element.
     * @type {string}
     * @default undefined
     */
    inputRef: {
      type: String,
      default: undefined,
    },

    /**
     * The hint to display.
     * @type {string}
     * @default null
     */
    hint: {
      type: String,
      default: null,
    },
  },

  computed: {
    classes() {
      return {
        [`deck-label--${this.size}`]: true,
      };
    },
  },
};
</script>

<style lang="scss">
.deck-label {
  font-weight: var(--deck-label-font-weight);
  font-size: var(--deck-label-font-size);
  line-height: var(--deck-label-line-height);
  color: var(--deck-label-color);
  letter-spacing: var(--deck-label-letter-spacing);
  display: flex;
  align-items: center;
}

.deck-label__label {
  font: inherit;
  color: inherit;
  letter-spacing: inherit;
}

.deck-label--dense {
  --deck-label-color: var(--z-color-label);
  --deck-label-font-size: var(--z-font-size-xsmall);
  --deck-label-font-weight: var(--z-font-weight-medium);
  --deck-label-line-height: var(--z-line-height-comfort);
  --deck-label-letter-spacing: 0.02em;
}

.deck-label--small {
  --deck-label-color: var(--z-color-label);
  --deck-label-font-size: var(--z-font-size-small);
  --deck-label-font-weight: var(--z-font-weight-medium);
  --deck-label-line-height: var(--z-line-height-comfort);
  --deck-label-letter-spacing: normal;
}

.deck-label--medium {
  --deck-label-color: var(--z-color-black);
  --deck-label-font-size: var(--z-font-size-medium);
  --deck-label-font-weight: var(--z-font-weight-bold);
  --deck-label-line-height: var(--z-line-height-comfort);
  --deck-label-letter-spacing: normal;
}
</style>
